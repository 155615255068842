<template>
  <div class="container-wrap">
    <Header :navSelIndex="4"></Header>
    <div class="header-con"></div>
    <b-container>
      <div class="bread-crumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>场景购</el-breadcrumb-item>
          <el-breadcrumb-item>所有组合场景</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="select-option-wrap">
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="空间：">
            <!-- <span :class="spaceAll?'space_all':'space_cancel'">全部</span> -->
            <!-- <span :class="spaceAll?'space_all':'space_cancel'">全部</span> -->
            <!-- <el-checkbox-group v-model="form.space"> -->
              <el-radio-group v-model="form.space" @change="handleCheckedSpace">
              <!-- <el-checkbox :class="spaceAll?'space_all':'space_cancel'">全部</el-checkbox>   -->
              <!-- <span :class="spaceAll?'space_all':'space_cancel'">全部</span> -->
              <el-radio label="">全部</el-radio>
              <el-radio v-for="(item,index) in spaceList" :key="index" :label="item.LABEL_ID">{{item.LABEL_NAME }}</el-radio>
              <!-- <div :class="spaceAll?'space_all':'space_cancel'">全部</div>   -->
              <!-- <el-checkbox label="all">全部</el-checkbox>
              <el-checkbox label="1">玄关</el-checkbox>
              <el-checkbox label="2">客厅</el-checkbox>
              <el-checkbox label="3">餐厅</el-checkbox>
              <el-checkbox label="4">卧室</el-checkbox>
              <el-checkbox label="5">卫浴/衣帽间</el-checkbox>
              <el-checkbox label="6">书房</el-checkbox>
              <el-checkbox label="7">茶室</el-checkbox>
              <el-checkbox label="8">厨房</el-checkbox>
              <el-checkbox label="9">儿童房</el-checkbox> -->
            </el-radio-group>
            <!-- </el-checkbox-group> -->
          </el-form-item>
          <!-- <el-form-item label="场景：">
            <el-checkbox-group v-model="form.scene">
              <el-checkbox label="all">全部</el-checkbox>
              <el-checkbox label="1">餐边柜搭配</el-checkbox>
              <el-checkbox label="2">餐桌搭配</el-checkbox>
              <el-checkbox label="3">水吧台搭配</el-checkbox>
              <el-checkbox label="4">茶桌搭配</el-checkbox>
              <el-checkbox label="5">操作台搭配</el-checkbox>
              <el-checkbox label="6">飘窗台搭配</el-checkbox>
              <el-checkbox label="7">客厅-边几搭配</el-checkbox>
              <el-checkbox label="8">茶几搭配</el-checkbox>
              <el-checkbox label="9">书桌搭配</el-checkbox>
              <el-checkbox label="10">洗手台搭配</el-checkbox>
              <el-checkbox label="11">餐厅-边柜搭配</el-checkbox>
              <el-checkbox label="12">卧室-床头柜搭配</el-checkbox>
              <el-checkbox label="13">电视柜搭配</el-checkbox>
              <el-checkbox label="14">玄关柜搭配</el-checkbox>
              <el-checkbox label="15">儿童房-柜饰搭配</el-checkbox>
              <el-checkbox label="16">梳妆台搭配</el-checkbox>
              <el-checkbox label="17">床上饰品搭配</el-checkbox>
              <el-checkbox label="18">衣帽间搭配</el-checkbox>
              <el-checkbox label="19">卧室-边几搭配</el-checkbox>
              <el-checkbox label="20">儿童房-床头柜</el-checkbox>
              <el-checkbox label="21">书柜搭配</el-checkbox>
              <el-checkbox label="22">客厅-柜饰</el-checkbox>
              <el-checkbox label="23">卧室-边柜搭配</el-checkbox>
              <el-checkbox label="24">书架搭配</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="分类：">
            <el-checkbox-group v-model="form.classify">
              <el-checkbox label="all">全部</el-checkbox>
              <el-checkbox label="1">摄影主题</el-checkbox>
              <el-checkbox label="2">收藏主题</el-checkbox>
              <el-checkbox label="3">香氛主题</el-checkbox>
              <el-checkbox label="4">现代主题</el-checkbox>
              <el-checkbox label="5">中式主题</el-checkbox>
              <el-checkbox label="6">手工主题</el-checkbox>
              <el-checkbox label="7">珠宝主题</el-checkbox>
              <el-checkbox label="8">音乐主题</el-checkbox>
            </el-checkbox-group>
          </el-form-item> -->
          <!-- <el-form-item label="价格："> -->
            <!-- <el-checkbox-group v-model="form.price">
              <el-checkbox label="all">全部</el-checkbox>
              <el-checkbox label="1">0-999</el-checkbox>
              <el-checkbox label="2">1000-1999</el-checkbox>
              <el-checkbox label="3">2000-2999</el-checkbox>
              <el-checkbox label="4">3000-3999</el-checkbox>
              <el-checkbox label="5">4000以上</el-checkbox>
            </el-checkbox-group> -->
            <!-- <el-radio-group v-model="form.price" @change="handleCheckedSpace">
              <el-radio label="">全部</el-radio>
              <el-radio label="1">0-999</el-radio>
              <el-radio label="2">1000-1999</el-radio>
              <el-radio label="3">2000-2999</el-radio>
              <el-radio label="4">3000-3999</el-radio>
              <el-radio label="5">4000以上</el-radio>
            </el-radio-group>
          </el-form-item> -->
          <!-- <el-form-item label="颜色：">
            <div class="colors">
              <div class="colors-items-wrap">
                <div :class="colorAll ? 'colors-item color-all actives' : 'colors-item color-all'"
                  @click="selectColors('')">全部</div>
                <div class="colors-item"
                  :style="item.isColor ? `background-color: ${item.COLOR};border:1px solid ${item.LABEL_NAME == '白色' ? '#dcdddf' : item.COLOR}` : `background-image: url('${item.IMG_PATH}');padding: 1px`"
                  v-for="(item, index) in colorsItems" :key="index" @click="selectColors(item)">
                  <div :class="item.LABEL_ID == 830 ? 'color-item-tip w43':'color-item-tip'">{{item.LABEL_NAME}}</div>
                  <i :class="item.checked ? 'el-icon-check checked' : ''"></i>
                </div>
              </div>
            </div>
          </el-form-item> -->
        </el-form>
      </div>
      <div class="solution-box video-box">
        <waterfall :col="colCount" :data="canvasList" class="solution">
          <template>
            <div
              class="solution-item"
              v-for="(item, index) in canvasList"
              :key="index"
              @click="toCanvasDetail(item)"
            >
              <div class="solution-item-ehome">
                <div class="solution-img">
                  <img class="mode-img" :src="item.coverUri" />
                </div>
                <!-- @click.stop="collectionMode(item)" -->
                <!-- <div
                  class="collection-button"
                  
                >
                  <i class="el-icon-star-off" v-if="item.collections == 0"></i>
                  <i class="el-icon-star-on" v-else></i>
                  <div class="collection-msg" v-if="item.collections == 0">
                    {{ $t("collectionButtonTitle") }}
                  </div>
                  <div class="collection-msg" v-else>
                    {{ $t("cancleButtonTItle") }}
                  </div>
                </div> -->
                <!-- <div class="solution-name ehome-solution-title-name">
                  {{ item.brand_name }}{{ item.style_name
                  }}{{ item.goods_name }}
                </div> -->
                <div class="solution-name ehome-solution-title-name">
                  {{ item.title }}
                </div>
                <!-- <div class="solution-user-box">¥{{ item.price }}</div> -->
              </div>
            </div>
          </template>
        </waterfall>
      </div>
      <el-pagination
        v-if="totalPage > 0"
        :page-count="totalPage"
        @current-change="changeCurrentPage"
        class="margin-top-80"
        background
        layout="prev, pager, next"
      ></el-pagination>
    </b-container>
    <Right></Right>
    <Footer></Footer>
  </div>
</template>

<script>
  import Header from "@/components/Navbar.vue";
  import Footer from "@/components/Footer.vue";
  import Right from "@/components/Right.vue";
  import dump from "@/util/dump";
  export default {
    components: {
      Header,
      Footer,
      Right
    },
    created(){
      this.form.space = "";
      this.getProgramme();
    },
    data() {
      return {
        form: {
          space: [],
          scene: [],
          classify: [],
          price: [],
          color: [],
        },
        colorAll: true,
        spaceAll: true,
        colorsItems: [
          {
            "PHONE_IMG": "",
            "COLOR": "#ff0000",
            "LABEL_ID": 805,
            "IMG_PATH": "http://e-home.oss-cn-shenzhen.aliyuncs.com/file/ef7c57d4-da01-4047-bc1e-23c4421109c1.jpg",
            "LABEL_NAME": "红色",
            "isColor": true,
            "checked": false
          },
          {
            "PHONE_IMG": "",
            "COLOR": "#ff8c00",
            "LABEL_ID": 821,
            "IMG_PATH": "",
            "LABEL_NAME": "橙色",
            "isColor": true,
            "checked": false
          },
          {
            "PHONE_IMG": "",
            "COLOR": "#ffff00",
            "LABEL_ID": 818,
            "IMG_PATH": "",
            "LABEL_NAME": "黄色",
            "isColor": true,
            "checked": false
          },
          {
            "PHONE_IMG": "",
            "COLOR": "#008000",
            "LABEL_ID": 822,
            "IMG_PATH": "",
            "LABEL_NAME": "绿色",
            "isColor": true,
            "checked": false
          },
          {
            "PHONE_IMG": "",
            "COLOR": "#0000ff",
            "LABEL_ID": 812,
            "IMG_PATH": "",
            "LABEL_NAME": "蓝色",
            "isColor": true,
            "checked": false
          },
          {
            "PHONE_IMG": "",
            "COLOR": null,
            "LABEL_ID": 830,
            "IMG_PATH": "http://e-home.oss-cn-shenzhen.aliyuncs.com/file/96348b13-ed57-4422-ac42-dc6429c7cb1c.png",
            "LABEL_NAME": "百搭色",
            "checked": false
          }
        ],
        colCount: 4,
        canvasList: [
          {
            "file_path": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/0d37da88-c36b-4841-93cb-621e75482486.png",
            "distribution_price": "",
            "discountPrice": "6968.00",
            "style_name": "极简",
            "style_type": 16,
            "sale_count": 0,
            "collections": 0,
            "first_label_name": "家具馆",
            "goods_name": "意式极简 优质实木框架 高档棉麻布艺沙发双人位/三人位",
            "fourth_label_id": "null",
            "third_label_id": 623,
            "video_path": "",
            "goods_id": 5017174,
            "brand_name": "森凯洛",
            "sort": 10,
            "first_label_id": 2,
            "sale_price": "6968.00",
            "promotion_price": "",
            "brand_id": "580",
            "operate_price": "",
            "sys_attribute": 1,
            "line_date": "2021-11-26 15:32:45",
            "third_label_name": "沙发",
            "fourth_label_name": "null",
            "goods_marque": "ZD-BS-BSFS18165-1",
            "is_promotion": 2,
            "second_style_type": 656,
            "second_label_name": "客厅",
            "goods_type": 3,
            "city_id": 440300
          },
          {
            "file_path": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/0d37da88-c36b-4841-93cb-621e75482486.png",
            "distribution_price": "",
            "discountPrice": "6968.00",
            "style_name": "极简",
            "style_type": 16,
            "sale_count": 0,
            "collections": 0,
            "first_label_name": "家具馆",
            "goods_name": "意式极简 优质实木框架 高档棉麻布艺沙发双人位/三人位",
            "fourth_label_id": "null",
            "third_label_id": 623,
            "video_path": "",
            "goods_id": 5017174,
            "brand_name": "森凯洛",
            "sort": 10,
            "first_label_id": 2,
            "sale_price": "6968.00",
            "promotion_price": "",
            "brand_id": "580",
            "operate_price": "",
            "sys_attribute": 1,
            "line_date": "2021-11-26 15:32:45",
            "third_label_name": "沙发",
            "fourth_label_name": "null",
            "goods_marque": "ZD-BS-BSFS18165-1",
            "is_promotion": 2,
            "second_style_type": 656,
            "second_label_name": "客厅",
            "goods_type": 3,
            "city_id": 440300
          },
          {
            "file_path": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/0d37da88-c36b-4841-93cb-621e75482486.png",
            "distribution_price": "",
            "discountPrice": "6968.00",
            "style_name": "极简",
            "style_type": 16,
            "sale_count": 0,
            "collections": 0,
            "first_label_name": "家具馆",
            "goods_name": "意式极简 优质实木框架 高档棉麻布艺沙发双人位/三人位",
            "fourth_label_id": "null",
            "third_label_id": 623,
            "video_path": "",
            "goods_id": 5017174,
            "brand_name": "森凯洛",
            "sort": 10,
            "first_label_id": 2,
            "sale_price": "6968.00",
            "promotion_price": "",
            "brand_id": "580",
            "operate_price": "",
            "sys_attribute": 1,
            "line_date": "2021-11-26 15:32:45",
            "third_label_name": "沙发",
            "fourth_label_name": "null",
            "goods_marque": "ZD-BS-BSFS18165-1",
            "is_promotion": 2,
            "second_style_type": 656,
            "second_label_name": "客厅",
            "goods_type": 3,
            "city_id": 440300
          },
          {
            "file_path": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/0d37da88-c36b-4841-93cb-621e75482486.png",
            "distribution_price": "",
            "discountPrice": "6968.00",
            "style_name": "极简",
            "style_type": 16,
            "sale_count": 0,
            "collections": 0,
            "first_label_name": "家具馆",
            "goods_name": "意式极简 优质实木框架 高档棉麻布艺沙发双人位/三人位",
            "fourth_label_id": "null",
            "third_label_id": 623,
            "video_path": "",
            "goods_id": 5017174,
            "brand_name": "森凯洛",
            "sort": 10,
            "first_label_id": 2,
            "sale_price": "6968.00",
            "promotion_price": "",
            "brand_id": "580",
            "operate_price": "",
            "sys_attribute": 1,
            "line_date": "2021-11-26 15:32:45",
            "third_label_name": "沙发",
            "fourth_label_name": "null",
            "goods_marque": "ZD-BS-BSFS18165-1",
            "is_promotion": 2,
            "second_style_type": 656,
            "second_label_name": "客厅",
            "goods_type": 3,
            "city_id": 440300
          },
          {
            "file_path": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/0d37da88-c36b-4841-93cb-621e75482486.png",
            "distribution_price": "",
            "discountPrice": "6968.00",
            "style_name": "极简",
            "style_type": 16,
            "sale_count": 0,
            "collections": 0,
            "first_label_name": "家具馆",
            "goods_name": "意式极简 优质实木框架 高档棉麻布艺沙发双人位/三人位",
            "fourth_label_id": "null",
            "third_label_id": 623,
            "video_path": "",
            "goods_id": 5017174,
            "brand_name": "森凯洛",
            "sort": 10,
            "first_label_id": 2,
            "sale_price": "6968.00",
            "promotion_price": "",
            "brand_id": "580",
            "operate_price": "",
            "sys_attribute": 1,
            "line_date": "2021-11-26 15:32:45",
            "third_label_name": "沙发",
            "fourth_label_name": "null",
            "goods_marque": "ZD-BS-BSFS18165-1",
            "is_promotion": 2,
            "second_style_type": 656,
            "second_label_name": "客厅",
            "goods_type": 3,
            "city_id": 440300
          },
          {
            "file_path": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/0d37da88-c36b-4841-93cb-621e75482486.png",
            "distribution_price": "",
            "discountPrice": "6968.00",
            "style_name": "极简",
            "style_type": 16,
            "sale_count": 0,
            "collections": 0,
            "first_label_name": "家具馆",
            "goods_name": "意式极简 优质实木框架 高档棉麻布艺沙发双人位/三人位",
            "fourth_label_id": "null",
            "third_label_id": 623,
            "video_path": "",
            "goods_id": 5017174,
            "brand_name": "森凯洛",
            "sort": 10,
            "first_label_id": 2,
            "sale_price": "6968.00",
            "promotion_price": "",
            "brand_id": "580",
            "operate_price": "",
            "sys_attribute": 1,
            "line_date": "2021-11-26 15:32:45",
            "third_label_name": "沙发",
            "fourth_label_name": "null",
            "goods_marque": "ZD-BS-BSFS18165-1",
            "is_promotion": 2,
            "second_style_type": 656,
            "second_label_name": "客厅",
            "goods_type": 3,
            "city_id": 440300
          },
          {
            "file_path": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/0d37da88-c36b-4841-93cb-621e75482486.png",
            "distribution_price": "",
            "discountPrice": "6968.00",
            "style_name": "极简",
            "style_type": 16,
            "sale_count": 0,
            "collections": 0,
            "first_label_name": "家具馆",
            "goods_name": "意式极简 优质实木框架 高档棉麻布艺沙发双人位/三人位",
            "fourth_label_id": "null",
            "third_label_id": 623,
            "video_path": "",
            "goods_id": 5017174,
            "brand_name": "森凯洛",
            "sort": 10,
            "first_label_id": 2,
            "sale_price": "6968.00",
            "promotion_price": "",
            "brand_id": "580",
            "operate_price": "",
            "sys_attribute": 1,
            "line_date": "2021-11-26 15:32:45",
            "third_label_name": "沙发",
            "fourth_label_name": "null",
            "goods_marque": "ZD-BS-BSFS18165-1",
            "is_promotion": 2,
            "second_style_type": 656,
            "second_label_name": "客厅",
            "goods_type": 3,
            "city_id": 440300
          },
          {
            "file_path": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/0d37da88-c36b-4841-93cb-621e75482486.png",
            "distribution_price": "",
            "discountPrice": "6968.00",
            "style_name": "极简",
            "style_type": 16,
            "sale_count": 0,
            "collections": 0,
            "first_label_name": "家具馆",
            "goods_name": "意式极简 优质实木框架 高档棉麻布艺沙发双人位/三人位",
            "fourth_label_id": "null",
            "third_label_id": 623,
            "video_path": "",
            "goods_id": 5017174,
            "brand_name": "森凯洛",
            "sort": 10,
            "first_label_id": 2,
            "sale_price": "6968.00",
            "promotion_price": "",
            "brand_id": "580",
            "operate_price": "",
            "sys_attribute": 1,
            "line_date": "2021-11-26 15:32:45",
            "third_label_name": "沙发",
            "fourth_label_name": "null",
            "goods_marque": "ZD-BS-BSFS18165-1",
            "is_promotion": 2,
            "second_style_type": 656,
            "second_label_name": "客厅",
            "goods_type": 3,
            "city_id": 440300
          },
          {
            "file_path": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/0d37da88-c36b-4841-93cb-621e75482486.png",
            "distribution_price": "",
            "discountPrice": "6968.00",
            "style_name": "极简",
            "style_type": 16,
            "sale_count": 0,
            "collections": 0,
            "first_label_name": "家具馆",
            "goods_name": "意式极简 优质实木框架 高档棉麻布艺沙发双人位/三人位",
            "fourth_label_id": "null",
            "third_label_id": 623,
            "video_path": "",
            "goods_id": 5017174,
            "brand_name": "森凯洛",
            "sort": 10,
            "first_label_id": 2,
            "sale_price": "6968.00",
            "promotion_price": "",
            "brand_id": "580",
            "operate_price": "",
            "sys_attribute": 1,
            "line_date": "2021-11-26 15:32:45",
            "third_label_name": "沙发",
            "fourth_label_name": "null",
            "goods_marque": "ZD-BS-BSFS18165-1",
            "is_promotion": 2,
            "second_style_type": 656,
            "second_label_name": "客厅",
            "goods_type": 3,
            "city_id": 440300
          },
          {
            "file_path": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/0d37da88-c36b-4841-93cb-621e75482486.png",
            "distribution_price": "",
            "discountPrice": "6968.00",
            "style_name": "极简",
            "style_type": 16,
            "sale_count": 0,
            "collections": 0,
            "first_label_name": "家具馆",
            "goods_name": "意式极简 优质实木框架 高档棉麻布艺沙发双人位/三人位",
            "fourth_label_id": "null",
            "third_label_id": 623,
            "video_path": "",
            "goods_id": 5017174,
            "brand_name": "森凯洛",
            "sort": 10,
            "first_label_id": 2,
            "sale_price": "6968.00",
            "promotion_price": "",
            "brand_id": "580",
            "operate_price": "",
            "sys_attribute": 1,
            "line_date": "2021-11-26 15:32:45",
            "third_label_name": "沙发",
            "fourth_label_name": "null",
            "goods_marque": "ZD-BS-BSFS18165-1",
            "is_promotion": 2,
            "second_style_type": 656,
            "second_label_name": "客厅",
            "goods_type": 3,
            "city_id": 440300
          }
        ],
        spaceList:[],
        currentPage: 1,
        totalPage: 5
      }
    },
    methods: {
      selectColors(obj) {
        console.log(obj)
        this.colorsItems.forEach(item => {
          item.checked = false;
        })
        if (obj) {
          obj.checked = !obj.checked;
          this.colorAll = false;
        } else {
          this.colorAll = true;
        }
        // color_id = obj ? obj.LABEL_ID : '';
        // currentPage = 1;
      },
      toCanvasDetail(canvasDetail) {
        let data = {
          type: 3,
          link: '/sceneInfo',
          params: {
            id: canvasDetail.id
          }
        };
        dump.link(data);
      },
      changeCurrentPage(info) {
        this.currentPage = info;
        this.$api.canvasSearch({
          sceneSpaceId : this.form.space,
          priceId: this.form.price,
          currentPage: this.currentPage
        }).then((res) =>{
          this.canvasList = res.data.list
          console.log(res.data)
        })
        // this.getModeList(info);
        this.$utils.scrollTop();
      },
      //场景分类
      getProgramme(){
        this.$api.getProgramme().then((res) => {
          this.spaceList = res.data;
          console.log("sceneList",this.spaceList)
        })
        this.getCanvasSearch()
      },
      //场景画布
      getCanvasSearch(){
        this.$api.canvasSearch({
          sceneSpaceId : this.form.space,
          priceId: this.form.price
        }).then((res) =>{
          this.canvasList = res.data.list
          this.totalPage = res.data.pages
          console.log(res.data)
        })
      },
      //选中空间或价钱
      handleCheckedSpace(){
        this.$api.canvasSearch({
          sceneSpaceId : this.form.space
          // priceId: this.form.price
        }).then((res) =>{
          this.canvasList = res.data.list
          this.totalPage = res.data.pages
          console.log(res.data)
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/style";
  @import "scenePurchase";
</style>
